
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import BrandLogo from "@/components/BrandLogo.vue";
import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";

export default defineComponent({
  mixins: [helperMixin],
  components: { BaseButtonPrimary, BrandLogo, BaseLink },
  emits: ["on-submit"],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    submitButtonText: { type: String },
    submitButtonLoadingText: { type: String },
    errorMsg: {
      default: "",
    },
    successMsg: {
      default: "",
    },
  },
  mounted() {
    this.setPageTitle(this.trans("layout.guest_page_title"));
  },
});
