
import { defineComponent } from "vue";
import { Default } from "@/enums";

export default defineComponent({
  computed: {
    brandLogo() {
      return Default.image;
    }
  }
});
