import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-right mt-3 mb-5" }
const _hoisted_2 = { class: "auth-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_form_group = _resolveComponent("base-form-group")!
  const _component_base_link = _resolveComponent("base-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_auth = _resolveComponent("auth")!

  return (_openBlock(), _createBlock(_component_auth, {
    title: _ctx.trans('login.title'),
    "submit-button-text": _ctx.trans('login.sign_in'),
    "submit-button-loading-text": _ctx.trans('login.signing_in'),
    loading: _ctx.loading,
    "error-msg": _ctx.errorMsg,
    onOnSubmit: _ctx.submit
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", null, [
          _createTextVNode(_toDisplayString(_ctx.trans("login.dont_have_an_account")) + " ", 1),
          _createVNode(_component_router_link, {
            to: _ctx.goTo(_ctx.RouteNames.Register),
            class: "text-theme"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("login.sign_up")), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_base_form_group, {
        label: _ctx.trans('login.email'),
        modelValue: _ctx.formData.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.email) = $event)),
        "invalid-message": _ctx.validationErrors.email
      }, null, 8, ["label", "modelValue", "invalid-message"]),
      _createVNode(_component_base_form_group, {
        "input-type": "password",
        label: _ctx.trans('login.password'),
        modelValue: _ctx.formData.password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.password) = $event)),
        "invalid-message": _ctx.validationErrors.password
      }, null, 8, ["label", "modelValue", "invalid-message"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_base_link, {
          to: {
          name: _ctx.RouteNames.PasswordReset,
        },
          class: "text-theme text-xs"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.trans("login.forgot_password")), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _: 1
  }, 8, ["title", "submit-button-text", "submit-button-loading-text", "loading", "error-msg", "onOnSubmit"]))
}