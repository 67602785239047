
import Auth from "@/components/Auth.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import { AttemptType } from "@/facades/auth";
import { authMixin, helperMixin } from "@/mixins";
import { defineComponent } from "vue";

export default defineComponent({
  components: { BaseFormGroup, BaseLink, Auth },
  mixins: [authMixin(AttemptType.Login), helperMixin],
});
